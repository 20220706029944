.noList{
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

.noList > li{
    display: inline-block;
}

.limitSize{
    overflow-y: auto;
    max-height: 200px;
}

.ebayItemCellImg{
    width: 100px;
}
.fixedCenter{
    position: fixed;
    bottom: 0px;
    left: 50%;
    width: auto;
    transform: translateX(-50%);
}

.subDropDownRightSide{
    right: 0px;
    top: 0px;
    /* float: right; */
    /* padding: .25rem 1.5rem; */
    width: 1px;
    max-width: 1px;
    overflow: hidden;
}

.overAndShow:hover > div{
    display: block !important;
}

.overAll{
    z-index: 9999 !important;
}