/* Customize the label (the container) */
.ctn_checkbox {
	display: block;
	position: relative;
	/* padding-left: 4px; */
	/* margin-bottom: 12px; */
	margin: auto;
	cursor: pointer;
	font-size: 22px;
	height: 25px;
	width: 25px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.ctn_checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.ctn_checkbox_native input{
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.ctn_checkbox_native .checkmark,
.ctn_checkbox .checkmark {
	position: absolute;
	height: 25px;
	width: 25px;
	background-color: #eee;
}

.ctn_checkbox .checkmark {
	top: 0;
	left: 0;

}

/* On mouse-over, add a grey background color */
.ctn_checkbox_native:hover input~.checkmark,
.ctn_checkbox:hover input~.checkmark {
	background-color: rgb(122, 122, 122);
}

/* When the checkbox is checked, add a blue background */
.ctn_checkbox_native input:checked~.checkmark,
.ctn_checkbox input:checked~.checkmark {
	background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.ctn_checkbox_native input:checked~.checkmark:after,
.ctn_checkbox input:checked~.checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.ctn_checkbox_native .checkmark:after,
.ctn_checkbox .checkmark:after {
	left: 9px;
	top: 5px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.form-control{
	font-weight: 600 !important;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	font-size: 15px;
	color: red;
	font-weight: normal;
}
::-moz-placeholder { /* Firefox 19+ */
	font-size: 15px;
	color: red;
	font-weight: normal;
}
::-ms-input-placeholder { /* IE 10+ */
	font-size: 15px;
	color: red;
	font-weight: normal;
}
::-moz-placeholder { /* Firefox 18- */
	font-size: 15px;
	color: red;
	font-weight: normal;
}
::placeholder { /* Firefox 18- */
	font-size: 15px;
	color: red;
	font-weight: normal;
}