#root, #root > div{
  height: 100%;
}

html, body{
  min-width: 455px;
}

.height100{
  height: 100%;
}

.fontNumber{
  font-family: monospace;
}

.ctnShowDataSheet{
  float: left;
}

.smallText{
  font-size: 12px !important;
}

.noselect {
  user-select: none; /* Prevents text selection */
  -webkit-user-select: none; /* For Safari */
  -moz-user-select: none; /* For Firefox */
  -ms-user-select: none; /* For Internet Explorer/Edge */
}

.middeSizeText{
  font-size: 13px;
}

.ctnShowSelected1{
  display: inline-block;
  margin-left: 30px;

  display: inline-block;
  margin-left: 50px;
  margin-top: 40px;
  border: 1px dashed #337ab7;
  padding: 15px;
}

.ctnShowSelected1 label{
  margin-right: 10px;
}


.listNameSheet{
  list-style-type: none;
  margin: 0px auto;
  padding: 0px;

}

.listNameSheet li{
  padding: 10px 15px;
  display: inline-block;
  background-color: #EEE;
  font-weight: bold;
  cursor: pointer;
}

.listNameSheet li:hover{
  background-color: #f5f5f5;
}

.listNameSheet li.active{
  background-color: #CCC;
}

.tbl_sheet_view{
  background-color: #eee;
  box-shadow: 0px 0px 8px #CCC;
}

.tbl_sheet_view td, .tbl_sheet_view th{
  padding: 5px 20px;
  border: 1px solid #CCC;
}

.tbl_sheet_view thead th.active{
  background-color: #337ab7;
  color: white;
}

.tbl_sheet_view thead th{
  cursor: pointer;
}

.tbl_sheet_view thead th:hover{
  background-color: #5797cf;
}


.menuWidth{
  min-width: 250px;
  width: 250px;
}

.overAuto{
  overflow: auto;
}

.listBrand{
  overflow: auto;
  height: 200px;
}

.pointer{
  cursor: pointer;
}

.col-resize {
  cursor: col-resize;
}

.ctnLoading{
  width: 100px;
}

.height45px{
  height: 45px;
}

#ctnChart{
  width: 80%;
  display: none;
}

/* .dropdown-menu.show{
  z-index: 8878
} */

.note-toolbar{
  z-index: auto !important;
}

.shadowIn{
  box-shadow: 0px 0px 5px inset #CCC;
}


.CodeMirror {
  border: 1px solid #eee !important;
  /* height: auto !important; */
}

.btnReload{
  transition: all 0.3s;
  outline: none;
  border: none;
  font-size: 20px;
}

.btnTicket{
  outline: none;
  border: none;
  font-size: 20px;
}

.btnReload:focus{
  outline: none;
  border: none;
}

.btnReload:hover{
  -ms-transform: rotate(50deg); /* IE 9 */
  -webkit-transform: rotate(50deg); /* Safari 3-8 */
  transform: rotate(50deg);
}

.bgEio{
  background-color: #f56600 !important;
}

.bgEbay{
  background-color: #818181 !important;
}

.DayPicker-Day--highlighted{
  background-color: #f0f8ff !important;
  color: #4a90e2; 
}
.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside),
.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}
.Selectable .DayPicker-Day,
.InputFromTo .DayPicker-Day {
  border-radius: 0 !important;
}
.Selectable .DayPicker-Day--start,
.InputFromTo .DayPicker-Day--start{
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
  background-color: blue !important;
}
.Selectable .DayPicker-Day--end,
.InputFromTo .DayPicker-Day--end{
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
  background-color: blue !important;
}

.InputFromTo{
  position: relative;
}

.InputFromTo .DayPickerInput-Overlay {
  width: 610px;
}

.withAuto{
  width: auto !important
}

.TEST2{
  background-color: red !important;
}

.InputFromTo-to .DayPickerInput-OverlayWrapper {
  /* margin-left: -213px; */
  position: absolute;
  left: 0px;
  width: 100%;
}

::-webkit-scrollbar{
  width: 10px;
  height: 8px;
  background-color: #FFF;
}
 
::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px #EEE;
  box-shadow:         inset 0 0 6px #EEE;
}

::-webkit-scrollbar-thumb{
  -webkit-box-shadow: inset 0 0 6px #414339;
  box-shadow:         inset 0 0 6px #414339;

  background: #EEE;
}

::-webkit-scrollbar-thumb:hover{
  cursor: pointer;
}

.card-header{
  padding: 0px !important;
}

.note-popover .popover-content>.note-btn-group, .note-toolbar>.note-btn-group{
  margin-top: 0px !important;
}

.bs-tooltip-top .arrow_red::before{
  border-top-color: #dc3545 !important;
}

.bs-tooltip-bottom .arrow_red::before{
  border-bottom-color: #dc3545 !important;
}

/* .modal{
  z-index: 9999 !important;
} */

.btnToggleMenu{
  display: none;
}


#bodyUnderHeader{
  top: 56px;
  bottom: 0px;

  position: absolute;
  width: 100%;
}

/* .mobileVopen .menuWidth{
  display: block !important;
} */

.btnToggleMenu{
  display: none !important;
}

.btnToggleMenu.open{
  display: block !important;
}

.columnImgShopify{
  /* max-width: 40% !important; */
  min-width: 192px !important;
}

@media (max-width: 1024px){
  .btnToggleMenu {
    display: block !important;
  }

  #bodyUnderHeader{
    padding-left: 0px !important;
  }

  /* .menuWidth {
    display: none !important;
  } */

  .menuWidth{
    background-color: #343a40;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    overflow-y: auto;
    overflow-x: auto;
    z-index: 9999;
    padding-left: 10px;
    padding-right: 10px;
  }

  .columnImgShopify{
    max-width: 100%!important;
  }

  .ctnCatalogItems > div > div:nth-of-type(2), .ctnCatalogItems > div > div:nth-of-type(5), .ctnCatalogItems > div > div:nth-of-type(6), .ctnCatalogItems > div > div:nth-of-type(7){
    display: none;
  }

  .ctnCatalogItems{
    min-width: auto !important;
  }

  .btnLeftMenuHiding{
    /* left: 0px !important; */
    display: none !important;
  }
}

.catalogItemHover:hover{
  background-color: #CCC;
}

.toast-body{
  max-width: 400px !important;
}

.tooltip-info-ebay{
  padding: 10px 20px !important;
  max-width: 500px !important;
  text-align: left !important;
}

.btnLeftMenuHiding{
  height: 50px;
  width: 15px;
  background-color: rgb(178 178 178);
  position: fixed;
  top: 50vh;
  left: 250px;
  text-align: center;
  line-height: 13px;
  color: white;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 1045;
  opacity: 0.7;
}

.btnLeftMenuHiding:hover{
  background-color: rgb(71, 71, 71);
  cursor: pointer;
}

.left0{
  left: 0px !important;
}

.ctn_winning_section{
  top: 80px;
  right: 0px;
  /* border: 2px solid #CCC;
  border-right: none; */
  /* padding: 2px; */
  background-color: rgba(13, 41, 167, 0.836);
  z-index: 1200;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow: hidden;
  overflow-y: auto;
  max-height: 500px;
}

/* .ctn_winning_section.smallWinning{
  right: -155px;
} */

.ctn_winning_section .closeWinning,
.ctn_winning_section.smallWinning .ctn_data_winning{
  display: none !important;
  border: none;
}
.ctn_winning_section.smallWinning .img_winning{
  border: 1px solid black;
}
.ctn_winning_section .number_winning{
  display: none;
}
.ctn_winning_section.smallWinning .number_winning{
  display: block;
}

.img_winning{
  width: 50px;
  height: 50px;
  background-color: #FFF;
  border: 2px solid black;
  border-right: none;
  position: relative;
  right: -2px;
}

.img_winning > *{
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.ctn_user_winning:first-child .img_winning,
.ctn_user_winning:first-child .ctn_data_winning{
  border-color: gold;
}

.ctn_user_winning{
  margin: 0px;
  /* margin-bottom: 5px; */
} 

.ctn_data_winning{
  width: 150px;
  height: 107px;
  overflow: hidden;
  padding: 5px;
  border: 2px solid black;
  /* margin-bottom: 4px; */
}


#bodyUnderHeader .cubeInlineCtPl{
	border: 1px solid #CCC;
}

.imgCtnPl{
	position: relative;
}

.imgCtnPl button{
	position: absolute;
}

.number_winning{
  color: #EEE;
  font-family: 'Courier New', Courier, monospace;
}


/* .userGrp-enter {
  opacity: 0;
}
.userGrp-enter-active {
  opacity: 1;
  background-color: red;
  transition: all 2s ease-in;
}
.userGrp-exit {
  opacity: 1;
}
.userGrp-exit-active {
  opacity: 0;
  background-color: green;
  transition: all 2ms ease-in;
} */





.ctn_winning_section:not(.smallWinning) .userGrp-enter{
  height: 0px;
}
.ctn_winning_section:not(.smallWinning) .userGrp-enter-active{
  height: 107px; /* Need to be adjusted if we add more content */
}
.ctn_winning_section:not(.smallWinning) .userGrp-exit{
  height: 107px; /* Need to be adjusted if we add more content */
}
.ctn_winning_section:not(.smallWinning) .userGrp-exit-active{
  height: 0px;
}


.smallWinning .userGrp-enter{
  height: 0px;
}
.smallWinning .userGrp-enter .number_winning{
  opacity: 1;
}
.smallWinning .userGrp-enter-active{
  height: 50px; /* Need to be adjusted if we add more content */
}
.smallWinning .userGrp-exit{
  height: 50px; /* Need to be adjusted if we add more content */
}
.smallWinning .userGrp-exit .number_winning{
  opacity: 0;
}
.smallWinning .userGrp-exit-active{
  height: 0px;
}


.ctn_user_winning {
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

/* .ctn_winning_section.smallWinning .ctn_data_winning{
  height: none !important;
  display: none !important;
}
.ctn_winning_section.smallWinning .ctn_user_winning {
  height: auto !important;
} */
/* .userGrp-enter-done{
  right: 0px
} */

.cubeStoreCreation{
  display: inline-block;
  margin: auto;
  width: auto;
  /* border: 1px solid #CCC; */
  border-right: none;
}

.cubeStoreCreation > div > div{
    border-right: 1px solid #CCC;
    width: 40px;
    height: 40px;
    display: inline-block;
}

.cubeStoreCreation > div > div:last-child{
    border-right: none;
}

.lineHoverColor:hover{
  background-color: #5797cf;
  font-weight: bold;
  color: #FFF;
  cursor: pointer;
}

.imgInfoTooltip{
  height: 100px;
}

.btnVariantsOver:hover{
  background-color: #343a40  !important;
}

.hoverImg{
  transition: transform .2s;
}
.hoverImg:hover{
  transform: scale(2);
  border: 1px solid #343a40;
  border-radius: 4px;
}
.fitCover{
  object-fit: cover;
}

.lightTooltip .arrow{
  color: rgb(65, 65, 65);
  opacity: 0.9;
}
.lightTooltip .tooltip-inner{
  background-color: rgb(65, 65, 65);
  opacity: 0.9;
}

.tooltip {
  z-index: 9999 !important; /* Set this to the desired z-index */
}

.menuElementAutoCreator{
  
}

.menuElementAutoCreator:hover{
  background-color: rgb(184, 184, 184);
  color: #000;
}

.menuGrpSelected > div:first-child{
  background-color: rgb(53, 53, 53);
  color: #EEE;
  border: 1px solid grey;
  border-bottom: 0px !important;
  border-left: 0px !important;
}
.menuGrpSelected > .menuChildsGrp{
  background-color: rgb(236 236 236);
  color: #000;
  border-left: 10px solid;
  opacity: 1;
}
.menuGrpSelected .badge:not(.badge-success){
  opacity: 0.4;
}
.menuGrpSelected .badge:hover{
  opacity: 1;
}

.menuChildsGrp{
  transition: all 4s;
  opacity: 0.0;
}

@media screen and (max-width: 767px) {
  .ctnSplittedInHalf > div {
    width: 100% !important;
    margin-bottom: 30px !important;
  }
}

.groupFormModified{
  display: flex;
  flex-direction: row;
  align-items: stretch;
  position: relative;
}
.groupFormModified span{
  border: 1px solid #CCC;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-right: 0px;
  padding: 5px 10px;
  background-color: #e7e7e7;
}
.groupFormModified input, .groupFormModified select{
  border: 1px solid #CCC;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 5px 10px;
  outline: 0px;
}
.groupFormModified input.noEditable{
  border: 1px solid #CCC;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 5px 10px;
}
.groupFormModified .btnEnd{
  position: absolute;
  top: 0px;
  right: 0px;
  outline: 0px !important;
}
.pointer, .pointer:focus{
  outline: 0 !important;
  box-shadow: none !important;
}

.modalDtFeature{
  z-index: 9990 !important;
}

/* @media screen and (max-width: 1100px) {
  .menufacturerSection{
    font-size: 12px !important;
  }
  .menufacturerSection button{
    font-size: 12px !important;
  }
} */

.menufacturerSection{
  font-size: 14px;
  max-width: 1200px;
}
.menufacturerSection button{
  font-size: 14px;
}

.middleView{
  font-size: 14px;
}
.smallView{
  font-size: 12px !important;
}
.smallView button{
  font-size: 12px !important;
}

.menufacturerSection .tblSkuCount{
/*   border: 1px solid #a8a8a8;
  border-radius: 5px; */
  border-collapse: inherit !important;
}

.zoomGraph > div{
  top: 5px;
  right: 5px;
}
.zoomGraph button{
  border: 1px solid #CCC;
  margin: 2px;
}
.zoomGraph button:hover{
  background-color: grey;
}

.infoGrowhSection{
  font-size: 11px;
}

.nav-item .nav-link > i{
  color: #007bff;
}
.nav-item .nav-link .bg-secondary > i{
  color: inherit;
}

.toolsQuickAccess{
  position: fixed;
  bottom: 85px;
  right: 5px;
  z-index: 9985 !important;
  font-size: 35px;
}

.toolsQuickAccess:hover > .mainCircle{
  box-shadow: 0px 0px 10px #494949;
  background-color: #FFF;
  color: #000;
}

.toolsQuickAccess > .mainCircle{
  position: relative;
  height: 60px;
  width: 60px;
  border-radius: 100px;
  background-color: #343a40;
  color: #FFF;
  border: 2px solid #343a40;
  transition: all 0.3s;
  z-index: 9987 !important;
}

.toolsQuickAccess:hover .subMenuQuickAccess{
  height: auto !important;
  opacity: 1;
  transition: opacity 0.3s;
  padding-bottom: 30px;
  padding-top: 15px;
}

.subMenuQuickAccess{
  opacity: 0.0;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 40px;
  color: #000;
  opacity: 0;
  background-color: #FFF;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  border: 1px solid #343a40;
  z-index: 9986 !important;
  height: 0px;
}

.toolQuickAccess{
  transition: all 0.3s;
}
.toolQuickAccess:hover{
  transform: scale(1.3);
}

.timedSection:hover{
  border-color: #5f5f5f !important;
}

.noLeftRadius > div[class*="control"]{
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.custom-control-label.danger::before{
  background-color: #dc3545 !important;
}