.centerFlexMulti{
	display: flex; 
	justify-content: center;
	align-items: center;
}

.ctnContentPlus{
	flex-direction: row;
	justify-content: space-evenly;
	align-items: stretch !important;
	min-height: 200px;
	flex-wrap: wrap;
}

.cubeInlineCtPl{
	width: 25%;
	min-width: 240px;
	vertical-align: middle;
	padding: 10px;
	text-align: center;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	overflow: hidden;
	margin-top: 10px;
	margin-bottom: 10px;
}

.cubeInlineCtPl > .ctnAplusLines{
	text-align: center;
	margin: auto;
}

.microCubePL{
	padding-left: 10px;
	padding-right: 10px;
	margin: auto;
}

.imgMicroPL{
	margin: auto;
	width: 100%;
	height: auto;
	/* border-radius: 300px; */
	max-height: 200px;
	max-width: 200px;
	backface-visibility: hidden;
	object-fit: contain;
}

.labelMicroPL{
	padding-top: 10px;
	font-weight: bold;
	word-wrap: break-word;
	overflow-wrap: break-word;
	white-space: normal!important;
	hyphens: auto;
}

.lineMicroPL{
	text-align: center;
	margin: 0px auto;
	display: flex;
	flex-direction: row;
	padding: 10px;
}

.cubeInlineCtPl .lineMicroPL:first-child:nth-last-child(1) .microCubePL:first-child:nth-last-child(1) img{
	max-height: 300px !important;
	max-width: 300px !important;
}

.descriptBlockPL{
	padding-left: 10px;
	padding-right: 10px;
	word-wrap: break-word;
	overflow-wrap: break-word;
	white-space: normal!important;
	hyphens: auto;
}

.spaceEvenly{
	justify-content: space-evenly;
}

.textBoldPl{
	font-weight: bold;
}