.ctnImgSelection
	align-self: flex-start
	margin-top: 30px
	margin-bottom: 30px
	position: relative
	display: inline-block
	width: 300px
	border: 1px solid #414339
	color: #eeeeee
	box-shadow: 0px 0px 13px 2px #414339
	/*> div
		position: fixed
		top: 0px
		left: 0px
		width: 100%*/

	.holder
		margin: auto
		height: 60px
		width: 100%
		background-color: #6E3CE8
		border-bottom: 1px solid grey
		display: flex
		flex-direction: row
		justify-content: flex-start
		align-items: center
		align-content: stretch

		img
			width: 60px
			height: 50px
			margin-left: 5px
			display: none
		> div
			display: flex
			flex-direction: column
			justify-content: center
			align-items: center
			height: 100%
			width: 100%
			
			.pathFile
				display: none
				font-weight: bold
				padding-bottom: 5px
				font-size: 12px
			.dragMsg
				font-size: 12px

	.search
		display: none
		> div
			position: relative
		form
			display: flex
			flex-direction: row
			border-bottom: 1px solid grey
			input[type="search"]
				// border: 0px
				height: 100%
				width: 100%
				padding: 5px
				border: 0px
			input[type="submit"]
				width: 40px
				border: 0px
				position: absolute
				top: 0px
				right: 0px
				bottom: 0px
				background-color: transparent
			> span
				display: flex
				justify-content: center
				align-items: center
				width: 40px
				font-size: 1.2em
				background-color: transparent
				.fa-search
					display: block
					width: inherit

	.listPicture
		overflow-x: hidden
		overflow-y: auto
		max-height: 300px
		.lineImg
			display: flex
			flex-direction: row
			justify-content: flex-start 
			flex-wrap: wrap
			align-items: center
			flex-wrap: nowrap
			padding: 3px
			cursor: pointer
			&:hover
				background-color: lighten(#6E3CE8, 15%)
			&.selected
				background-color: lighten(#6E3CE8, 10%)
			.imgCtn
				float: left
				border: 1px solid grey
				max-width: 50px
				height: 50px
				width: 50px
				margin-right: 10px
				background-position: center
				background-repeat: no-repeat
				background-origin: content-box
				background-size: cover
			> span
				word-wrap: break-word
				&.imgTitle2
					margin-left: 10px
					text-shadow: 0px 0px 8px white
					font-weight: bold


.lineImgEbay
	&:hover
		background-color: #EEE